import React from "react";
import "./Testimonials.scss";
import LeftQuote from "../../assets/quotes-left.png";
import RightQuote from "../../assets/quotes-right.png";

const Testimonials = () => {
  return (
    <>
      <section className="testimonials position-relative bg-success py-12 py-60 py-xxl-80">
        <img src={LeftQuote} alt="" className="left-quote" />
        <img src={RightQuote} alt="" className="right-quote" />
        <div className="container">
          <h4 className="text-center text-white fw-normal mb-6 w-lg-75 mx-auto">
            "When you reduce friction, make something easy, people do more of
            it.”
          </h4>
          <h3 className="text-end text-white mb-2 w-lg-75 mx-auto ms-lg-11">
            Jeff Bezos
          </h3>
          <p className="text-end text-white fw-normal fst-italic fs-20  mb-0 w-lg-75 mx-auto">
            Founder Amazon.com
          </p>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
