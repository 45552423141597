import React from "react";
import Dashboard from "../components/Dashboard";

const DashboardPage = () => {
  return (
    <div id="landingRoot" className="flex flex-col">
      <Dashboard />
    </div>
  );
};

export default DashboardPage;
