export const handleFrictionCalculate = (updatedAnswers, arraySurvey) => {
  const subset = updatedAnswers.slice(0, 10);
  const calculatedScores = subset.reduce(
    (acc, curr, i) => {
      const question = arraySurvey[i];
      const score = question.choices[curr].value;
      return {
        frictionFactor: acc.frictionFactor + score.frictionFactor,
        acceleratorValue: acc.acceleratorValue + score.acceleratorValue,
      };
    },
    { frictionFactor: 0, acceleratorValue: 0 }
  );

  //Cap the friction factor at 75

  calculatedScores.frictionFactor = Math.min(
    calculatedScores.frictionFactor,
    75
  );

  return calculatedScores;
};

export const fcFormCalculate = (form) => {
  const { acceleratorValue, growthValue, seedValue, sliceOptions } = form;
  const acceleratorFactor = acceleratorValue / sliceOptions;

  let factorsList = [];
  let networkGrowList = [];
  for (let i = 0; i <= sliceOptions; i++) {
    let factors = [];
    let netWorkGrow = [];
    const frictionFactor = 1 - i / sliceOptions;

    const netFactor = frictionFactor + acceleratorFactor;

    for (let j = 0; j < 7; j++) {
      if (j === 0) {
        let temp = netFactor * growthValue;
        // temp = parseFloat(temp).toFixed(2)
        let neg = temp * seedValue;
        factors.push(temp);

        // neg = Math.round(neg)
        netWorkGrow.push(neg);
      } else {
        let temp = factors[j - 1] * netFactor;
        let neg = netWorkGrow[j - 1] * temp;

        factors.push(temp);
        netWorkGrow.push(neg);
      }
    }
    factorsList.push(factors);
    networkGrowList.push(netWorkGrow);
  }
  return { factors: factorsList, netExpGrow: networkGrowList };
};
