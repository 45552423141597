import React from "react";
import { Card, Slider } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ReactSpeedometer from "react-d3-speedometer";

const muiTheme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        thumb: {
          color: "#bdbdbd",
        },
        track: {
          color: "gray",
        },
        rail: {
          color: "black",
        },
      },
    },
  },
});

const FrictionDialCard = ({ frictionFactor, onSetFrictionFactor }) => {
  return (
    <Card className="p-2">
      <div
        className="flex justify-center pb-5 p-3 text-badTeal text-3xl"
        variant="h4"
        component="div"
      >
        Friction Factor
      </div>
      <div className="flex justify-between flex-col flex-col-reverse xl:flex-row">
        <div className="flex-1 ml-8 mr-8 mt-0 xl:mt-10">
          <div
            className="font0-bold flex justify-center pt-0 pb-5 p-3 text-badTeal text-2xl"
            variant="h6"
            component="div"
          >
            Your Friction Factor is {frictionFactor}
          </div>
          <div className="text-lg" component="div">
            You may increase and decrease the friction factor with the slider to
            see the effects on your projected network growth
          </div>
        </div>
        <div className="flex-1 flex justify-around">
          <div className="test mb-0 m-5">
            <div
              className="bg-slate-100 rounded-full"
              style={{
                width: "300px",
                marginBottom: "-150px",
              }}
            >
              <ReactSpeedometer
                maxValue={75}
                value={frictionFactor}
                valueTextFontSize={"0"}
                needleColor="black"
                segments={5}
                ringWidth={90}
                maxSegmentLabels={0}
                needleHeightRatio={0.68}
                needleTransition="null"
                segmentColors={[
                  "#6CC43C",
                  "#C8DD16",
                  "#FEC200",
                  "#FF6D00",
                  "#FF3632",
                ]}
              />
            </div>
            <div
              style={{ width: "300px" }}
              className="p-5 pb-0 bg-gray-100 w-fit"
            >
              <ThemeProvider theme={muiTheme}>
                <Slider
                  sx={{
                    width: 225,
                    margin: "3px",
                    padding: "10px",
                  }}
                  className="w-40"
                  value={frictionFactor}
                  onChange={(e) => onSetFrictionFactor(e.target.value)}
                  aria-label="Default"
                  max={75}
                />
              </ThemeProvider>
            </div>
            <div
              style={{ width: "300px" }}
              className="flex justify-around bg-gray-100 rounded-b-2xl pb-5"
            >
              <div className="w-300 text-lg flex items-center justify-center ml-5">
                Slider Value
              </div>
              <div className="border bg-white w-20 text-lg flex items-center mr-5 justify-center">
                {frictionFactor}
              </div>
            </div>
            <div className="bg-white h-12" />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default FrictionDialCard;
