import React from "react";
import { Card } from "@mui/material";

const ProjectedNetworkCard = ({ results }) => {
  return (
    <Card className="p-2">
      <div
        className="flex justify-center pb-5 p-3 text-badTeal text-3xl"
        variant="h4"
        component="div"
      >
        Projected Network Growth
      </div>
      <div className="text-2xl">
        {results.map((res, i) => {
          return (
            <div className="flex justify-around" key={`${res}_${i}`}>
              {parseFloat(res).toFixed(0)}
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default ProjectedNetworkCard;
