// Footer.jsx
import React, { useState } from "react";
import PrivacyPolicyModal from "../ui/PrivacyPolicyModal";

function Year() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  return (
    <span className="text-white footer-text fw-semibold copyright">
      Copyright © {currentYear}
    </span>
  );
}

function Footer() {
  return (
    <footer className="py-4 py-lg-6 bg-warning text-center">
      <Year />
      <span className="mx-2 fs-12 text-white">-</span>
      <PrivacyPolicyModal type="disclaimer" />
      <span className="mx-2 fs-12 text-white">-</span>
      <PrivacyPolicyModal type="tou" />
    </footer>
  );
}

export default Footer;
