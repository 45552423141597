export default {
  networkGrowthPotential: {
    name: "networkGrowthPotential",
    title: "Network Growth Potential",
    alt: "Network Growth",
    type: "radiogroup",
    choices: [
      {
        value: { frictionFactor: 10, acceleratorValue: 0 },
        text: "In order to become part of your network the prospect must become a representative, make a product purchase or both",
      },
      {
        value: { frictionFactor: 8, acceleratorValue: 0 },
        text: "Some sort of purchase is required but growth is systems driven, ie. using funnels to convert prospects to customers",
      },
      {
        value: { frictionFactor: 3, acceleratorValue: 0 },
        text: "A person can join my network at no cost and with no commitment of future purchases",
      },
      {
        value: { frictionFactor: 1, acceleratorValue: 2 },
        text: "In addition to no cost and no commitment my network can grow passively via systems-driven growth",
      },
    ],
    isRequired: true,
  },
  productSalesGrowthPotential: {
    name: "productSalesGrowthPotential",
    title: "Product Sales Growth Potential",
    alt: "Sales Growth",
    type: "radiogroup",
    choices: [
      {
        value: { frictionFactor: 10, acceleratorValue: 0 },
        text: "I make the sale and my customers place orders through me",
      },
      {
        value: { frictionFactor: 8, acceleratorValue: 0 },
        text: "I make the sale but my customers place orders through a website",
      },
      {
        value: { frictionFactor: 6, acceleratorValue: 0 },
        text: "I make the initial sale and then customers are on auto-ship",
      },
      {
        value: { frictionFactor: 1, acceleratorValue: 2 },
        text: "I don't make the initial sale - customers order directly from the company and all sales and support are company-driven",
      },
    ],
    isRequired: true,
  },
  supportingYourNetwork: {
    name: "supportingYourNetwork",
    title: "Supporting your Network",
    alt: "Network Support",
    type: "radiogroup",
    choices: [
      {
        value: { frictionFactor: 9, acceleratorValue: 0 },
        text: "I provide the majority of support to new business owners in my network",
      },
      {
        value: { frictionFactor: 8, acceleratorValue: 0 },
        text: "The company provides general support and motivation via events",
      },
      {
        value: { frictionFactor: 6, acceleratorValue: 0 },
        text: "I have strong upline support and good company materials to support my network",
      },
      {
        value: { frictionFactor: 1, acceleratorValue: 2 },
        text: "My company provides custom support/coaching/and accountability to each member of my network without my direct involvement",
      },
    ],
    isRequired: true,
  },
  strengthOfProduct: {
    name: "strengthOfProduct",
    title: "Strength of your Product",
    alt: "Product Strength",
    type: "radiogroup",
    choices: [
      {
        value: { frictionFactor: 10, acceleratorValue: 0 },
        text: "The product I sell can be found through other channels",
      },
      {
        value: { frictionFactor: 8, acceleratorValue: 0 },
        text: "Products similar to mine can be found through other channels",
      },
      {
        value: { frictionFactor: 4, acceleratorValue: 0 },
        text: "My product is unique in its market",
      },
      {
        value: { frictionFactor: 1, acceleratorValue: 2 },
        text: "My product is unique and is backed by a strong competitive advantage",
      },
    ],
    isRequired: true,
  },
  strengthMarketContext: {
    name: "strengthMarketContext",
    title: "Strength of your Product within Market Context",
    alt: "Product within Market Context",
    type: "radiogroup",
    choices: [
      {
        value: { frictionFactor: 8, acceleratorValue: 0 },
        text: "My potential customers need for my product is unknown to them",
      },
      {
        value: { frictionFactor: 6, acceleratorValue: 0 },
        text: "My potential customers need for my product is known to them but they have other options in the marketplace to serve that need",
      },
      {
        value: { frictionFactor: 3, acceleratorValue: 0 },
        text: "My potential customers know they need my product, but there is little in the marketplace to serve the need",
      },
      {
        value: { frictionFactor: 1, acceleratorValue: 2 },
        text: "I can provide a product that addresses a known need of my customers and does not require them to make a purchase",
      },
    ],
    isRequired: true,
  },
  IndValueAtt: {
    name: "IndValueAtt",
    title: "Strength of Individual Value Attribution",
    alt: "Individual Value",
    type: "radiogroup",
    choices: [
      {
        value: { frictionFactor: 9, acceleratorValue: 0 },
        text: "The value delivered to the end user is competitive vs the competition in price and value",
      },
      {
        value: { frictionFactor: 8, acceleratorValue: 0 },
        text: "The value delivered to the end user cannot be delivered without my company",
      },
      {
        value: { frictionFactor: 4, acceleratorValue: 0 },
        text: "The value delivered to the end user cannot be delivered without me (for example: can your company deliver comparable value to the end user by providing the product directly to them…without going through you?)",
      },
      {
        value: { frictionFactor: 1, acceleratorValue: 2 },
        text: "The value delivered to the end user is amplified significantly by my contribution (ie. there is no way for my company to deliver a comparable value to my customer without me in the equation)",
      },
    ],
    isRequired: true,
  },
  strengthOfSystems: {
    name: "strengthOfSystems",
    title: "Strength of Systems",
    alt: "System Strength",
    type: "radiogroup",
    choices: [
      {
        value: { frictionFactor: 9, acceleratorValue: 0 },
        text: "I implement systems as I see fit to grow my business",
      },
      {
        value: { frictionFactor: 8, acceleratorValue: 0 },
        text: "My upline has proven systems and they provide access and support",
      },
      {
        value: { frictionFactor: 5, acceleratorValue: 0 },
        text: "My company has systems that generate leads and I follow up",
      },
      {
        value: { frictionFactor: 1, acceleratorValue: 1 },
        text: "My company generates the leads and closes the sale",
      },
      {
        value: { frictionFactor: 0, acceleratorValue: 2 },
        text: "My company takes care of all aspects of customer acquisition and support and provides tools for active and passive network building",
      },
    ],
    isRequired: true,
  },
  desiredJourney: {
    name: "desiredJourney",
    title: "Is your Business Aligned with your Desired Journey",
    type: "radiogroup",
    alt: "Business/Journey Alignment",
    choices: [
      {
        value: { frictionFactor: 9, acceleratorValue: 0 },
        text: "I struggle with my comfort of talking to others about what I do",
      },
      {
        value: { frictionFactor: 8, acceleratorValue: 0 },
        text: "I feel stressed when I look at my day-to-day action requirements",
      },
      {
        value: { frictionFactor: 5, acceleratorValue: 0 },
        text: "I'm concerned that I have conflicting objectives ie business building vs. spending time with family",
      },
      {
        value: { frictionFactor: 1, acceleratorValue: 0 },
        text: "I feel energized and challenged by my day-to-day activities and I feel good about the direction I am growing",
      },
    ],
    isRequired: true,
  },
  companyGrowthPotential: {
    name: "companyGrowthPotential",
    title:
      "Company Growth Potential (while value is King growth potential can still provide powerful tailwinds for your business growth)",
    alt: "Company Growth Potential",
    type: "radiogroup",
    choices: [
      {
        value: { frictionFactor: 9, acceleratorValue: 0 },
        text: "My company is established and growth is challenging",
      },
      {
        value: { frictionFactor: 8, acceleratorValue: 0 },
        text: "My company is growing but most of the growth is internationally",
      },
      {
        value: { frictionFactor: 3, acceleratorValue: 0 },
        text: "My company has massive growth potential in the US and abroad but competition is everywhere",
      },
      {
        value: { frictionFactor: 1, acceleratorValue: 0 },
        text: "My company has massive growth potential and a strong competitive advantage",
      },
    ],
    isRequired: true,
  },
  valueVGrowth: {
    name: "valueVGrowth",
    title: "Orientation toward Value vs Growth",
    alt: "Growth vs Value",
    type: "radiogroup",
    choices: [
      {
        value: { frictionFactor: 5, acceleratorValue: 0 },
        text: "If my organization did not grow for the next 12 months my income would decrease                     ",
      },
      {
        value: { frictionFactor: 0, acceleratorValue: 1 },
        text: "If my organization did not grow for 12 months by income could stay the same or potentially increase",
      },
    ],
    isRequired: true,
  },
  patientInfo: {
    name: "seedValue",
    type: "panel",
    title: "How many people will you personally recruit for your company?",
    inputType: "number",
  },
  growthValue: {
    name: "growthValue",
    type: "panel",
    title: "How many people will each member of your team recruit?",
    inputType: "number",
  },
};
