import React, { useEffect, useState } from "react";
import FrictionDialCard from "./FrictionDialCard";
import NetworkFrictionParametersCard from "./NetworkFrictionParametersCard";
import ParameterCard from "./ParameterCard";
import ProjectedNetworkCard from "./ProjectedNetworkCard";
import { fcFormCalculate } from "../util";
// import FCChart from "./FCChart";

const ResultsDashboard = ({
  initalScore,
  answers,
  arraySurvey,
  onEditAnswer,
}) => {
  const [scores, setScores] = useState({ ...initalScore, sliceOptions: 100 });
  const [results, setResult] = useState([]);
  const [chartData, setChartData] = useState(null);

  const calculateResults = () => {
    const { frictionFactor, seedValue } = scores;
    const chart = fcFormCalculate(scores);
    const netGrowArray = chart["netExpGrow"][frictionFactor];
    netGrowArray.unshift(seedValue);

    setChartData(chart);
    setResult(netGrowArray);
  };

  const handleReCalculate = () => {
    const survey = JSON.parse(localStorage.getItem("surveyAnswers"));
    survey.splice(10, 2, scores.seedValue, scores.growthValue);
    localStorage.setItem("surveyAnswers", JSON.stringify(survey));
    calculateResults();
  };

  useEffect(() => {
    calculateResults();
  }, []);

  return (
    <>
      <div className="flex max-w-screen-2xl m-auto">
        <div className="grid md:grid-cols-2 gap-7 p-5">
          <NetworkFrictionParametersCard
            answers={answers}
            arraySurvey={arraySurvey}
            onEditAnswer={onEditAnswer}
          />
          <FrictionDialCard
            frictionFactor={scores.frictionFactor}
            onSetFrictionFactor={(updatedFF) =>
              setScores({ ...scores, frictionFactor: updatedFF })
            }
          />
          <ParameterCard
            scores={scores}
            onTextChange={(field, value) =>
              setScores({ ...scores, [field]: value })
            }
            onRecalculate={() => handleReCalculate()}
          />
          <ProjectedNetworkCard results={results} />
        </div>
      </div>
    </>
  );
};

export default ResultsDashboard;
