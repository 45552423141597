import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Banner.scss";

const Banner = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  let navigate = useNavigate();
  const [surveyExists, setSurveyExists] = useState(false);
  const [userExists, setUserExists] = useState(false);

  useEffect(() => {
    const survey = localStorage.getItem("surveyAnswers");
    const user = localStorage.getItem("networkUser");
    if (survey) {
      setSurveyExists(true);
    }
    if (user) {
      setUserExists(true);
    }
  }, []);

  const handleScrollDown = () => {
    window.scrollBy({
      top: 120,
      behavior: "smooth",
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (name.trim().length === 0 || email.trim().length === 0) {
      return;
    }

    const new_contact = {
      "First name": name, //Replace with first name of the user
      "Last name": "", //Replace with last name of the user
      Email: email, //Replace with email of the user
    };

    const identifier = email;

    await window.fwcrm.identify(identifier, new_contact);
    localStorage.setItem("networkUser", JSON.stringify(new_contact));

    //history push
    navigate("/survey");
  };

  const handleRedo = () => {
    localStorage.removeItem("surveyAnswers");
    navigate("/survey");
  };

  return (
    <>
      <div class="banner-bg py-80 py-lg-120 position-relative">
        <div className="container">
          <div class="row align-items-center justify-content-between banner-content">
            <div class="col-lg-6">
              <h2 class="mb-6 text-white fw-normal">
                There's <span className="underline-text"> One Thing</span>{" "}
                Stopping the Growth of your Network Marketing Organization
              </h2>
              <h1 className="mb-0 text-primary fw-bolder">
                Your Friction Factor!
              </h1>
            </div>
            {/* {userExists && surveyExists ?

<div class="col-lg-4">
              <div className="p-6 rounded header-form" onSubmit={handleFormSubmit}>
              <button onClick={handleRedo} className="btn btn-success w-100 rounded-pill text-white">
                        Redo Survey
                      </button>
                      <div className="m-5 flex justify-around">
                        <h6 className="text-white mb-2 fs-14">OR</h6>
                        </div>

                <button onClick={()=>navigate("/survey")} className="btn btn-success w-100 rounded-pill text-white">
                        Go To Dashboard
                      </button>
                </div>
                
            </div>
            : */}

            <div class="col-lg-4">
              <form
                action="#"
                className="p-6 rounded header-form"
                onSubmit={handleFormSubmit}
              >
                <div className="row">
                  <div className="col-12 mb-5">
                    <label htmlFor="name" className="text-white mb-2 fs-14">
                      First Name
                    </label>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      id="name"
                      name="first-name"
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="col-12 mb-5">
                    <label htmlFor="email" className="text-white mb-2 fs-14">
                      Your Best Email Adress
                    </label>
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                    />
                  </div>
                  <div className="col-12 mt-5">
                    <button
                      type="submit"
                      className="btn btn-success w-100 rounded-pill text-white"
                    >
                      Discover your friction factor
                    </button>
                  </div>
                  {surveyExists && (
                    <div className="col-12 mt-5">
                      <button
                        onClick={handleRedo}
                        className="btn btn-success w-100 rounded-pill text-white"
                      >
                        Redo Survey
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
            {/* // :
            // <div class="col-lg-4">
            //   <div className="p-6 rounded header-form" onSubmit={handleFormSubmit}>
            //   <button onClick={handleRedo} className="btn btn-success w-100 rounded-pill text-white">
            //             Redo Survey
            //           </button>
            //           <div>
            //             <h6>OR</h6>
            //             </div>

            //     <button onClick={()=>navigate("/survey")} className="btn btn-success w-100 rounded-pill text-white">
            //             Go To Dashboard
            //           </button>
            //     </div>
                
            // </div> */}
            {/* } */}

            <div
              class="text-center move-down flex justify-around"
              onClick={handleScrollDown}
            >
              <div>
                <svg
                  class="animated-chevron"
                  width="38"
                  height="21"
                  viewBox="0 0 38 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.9994 20.8812L20.8243 19.2926L38 4.17795L34.3502 0.0345535L19 13.5383L3.64984 0.0345535L0 4.17795L17.1757 19.2926L18.9994 20.8812Z"
                    fill="#fff"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
