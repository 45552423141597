import Layout from "./components/layouts/Layout";
import "./scss/bootstrap.scss"; //   "../src/scss/bootstrap.scss";
import "./Global.scss";
import Banner from "./components/ui/Banner";
import Content from "./components/ui/Content";
import Testimonials from "./components/ui/Testimonials";

function App() {
  return (
    <>
      <Layout>
        <Banner />
        <Content />
        <Testimonials />
      </Layout>
    </>
  );
}

export default App;
