import React from "react";
import {
  Card,
  Button,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Survey = ({
  qIndex,
  onAnswer,
  onNav,
  onComplete,
  arraySurvey,
  existingAnswers,
  initalScore,
  isEdit,
}) => {
  const question = arraySurvey[qIndex];

  const renderRadio = () => {
    return (
      <FormControl key={qIndex}>
        <RadioGroup
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
          onChange={(e) => onAnswer(e.target.value)}
          value={existingAnswers[qIndex] || null}
        >
          {question.choices.map((choice, i) => {
            return (
              <div className="p-2 md:p-4 pl-6 border-2 m-1 rounded-full">
                <FormControlLabel
                  key={choice.text}
                  value={i}
                  control={<Radio />}
                  label={choice.text}
                />
              </div>
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  const renderOther = () => {
    return (
      <div key={qIndex} className="flex justify-around">
        <TextField
          sx={{ textAlign: "center" }}
          defaultValue={existingAnswers[qIndex]}
          type="number"
          id="outlined-basic"
          InputProps={{ min: 0 }}
          onChange={(e) => onAnswer(e.target.value)}
        />
      </div>
    );
  };

  return (
    <Card sx={{ maxWidth: 550, padding: "30px 15px" }}>
      <div className="flex">
        <div className="flex-1">
          {qIndex > 0 && !isEdit ? (
            <Button
              startIcon={<ArrowBackIcon />}
              disabled={qIndex === 0}
              onClick={() => onNav(-1)}
            >
              Previous
            </Button>
          ) : null}
        </div>
        <div className="flex-1 flex justify-around">
          <div style={{ width: 75, height: 75 }}>
            <CircularProgressbar
              styles={buildStyles({
                textColor: "#417980",
                pathColor: "#417980",
              })}
              value={((qIndex + 1) / arraySurvey.length) * 100}
              text={`${qIndex + 1}/${arraySurvey.length}`}
            />
          </div>
        </div>
        <div className="flex-1" />
      </div>
      <div>
        {qIndex >= 10 && initalScore ? (
          <div>
            Your friction factor is{" "}
            <span className="font-bold">{initalScore.frictionFactor}</span>.
            Just 2 more questions, and we will be able to show you your
            organizational growth potential.
          </div>
        ) : null}
        <div className="text-xl flex justify-around m-3 text-center">
          {question.title}
        </div>

        {question.type === "radiogroup" ? renderRadio() : renderOther()}
      </div>
      <div className="mt-2">
        <div className="flex justify-around">
          <div>
            {qIndex === arraySurvey.length - 1 ? (
              <Button
                sx={{
                  borderRadius: 28,
                  backgroundColor: "#417980",
                  paddingLeft: "35px",
                  paddingRight: "35px",
                }}
                disabled={!existingAnswers[qIndex]}
                variant="contained"
                onClick={() => onComplete()}
              >
                Complete
              </Button>
            ) : (
              <Button
                sx={{
                  borderRadius: 28,
                  backgroundColor: "#417980",
                  paddingLeft: "35px",
                  paddingRight: "35px",
                }}
                disabled={
                  qIndex === arraySurvey.length - 1 || !existingAnswers[qIndex]
                }
                variant="contained"
                onClick={() => onNav(1)}
              >
                {isEdit ? "Dashboard" : "Next"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Survey;
