import React, { useState, useEffect } from "react";
import surveyPart1 from "../assets/survey/survey";
import Survey from "./Survey";
import { handleFrictionCalculate } from "../util";
import ResultsDashboard from "./ResultsDashboard";

const arraySurvey = Object.keys(surveyPart1).map((key) => surveyPart1[key]);

const Dashboard = () => {
  const [surveyState, setSurveyState] = useState({
    complete: false,
    qIndex: 0,
    answers: [],
  });
  const [editQuestion, setEditQuestion] = useState({ edit: false, qIndex: 0 });

  useEffect(() => {
    const storageAnswers = localStorage.getItem("surveyAnswers");
    if (storageAnswers) {
      setSurveyState({
        qIndex: 11,
        answers: JSON.parse(storageAnswers),
        complete: true,
      });
    }
  }, []);

  const handleAnswer = (answer) => {
    const { qIndex, answers } = surveyState;
    let updatedAnswers = answers;
    updatedAnswers.splice(qIndex, 1, answer);
    setSurveyState({ ...surveyState, answers: updatedAnswers });
  };

  const handleSurveyComplete = () => {
    localStorage.setItem("surveyAnswers", JSON.stringify(surveyState.answers));
    setSurveyState({ ...surveyState, complete: true });
  };

  const handleEditAnswer = (answer) => {
    const { answers } = surveyState;
    let updatedAnswers = answers;
    updatedAnswers.splice(editQuestion.qIndex, 1, answer);
    setSurveyState({ ...surveyState, answers: updatedAnswers });
  };

  const handleEditNav = (dir) => {
    if (dir === 1) {
      localStorage.setItem(
        "surveyAnswers",
        JSON.stringify(surveyState.answers)
      );
    }
    setEditQuestion({ edit: false });
  };

  let initalScore;
  if (surveyState.qIndex > 9) {
    initalScore = handleFrictionCalculate(surveyState.answers, arraySurvey);
    const seedValue = parseInt(surveyState.answers[10]);
    const growthValue = parseFloat(surveyState.answers[11]);
    initalScore = { ...initalScore, seedValue, growthValue };
  }

  if (editQuestion.edit) {
    return (
      <div className="flex justify-around md:mt-10 grow">
        <Survey
          arraySurvey={arraySurvey}
          qIndex={editQuestion.qIndex}
          onNav={handleEditNav}
          onAnswer={handleEditAnswer}
          onComplete={handleSurveyComplete}
          existingAnswers={surveyState.answers}
          initalScore={initalScore}
          isEdit={true}
        />
      </div>
    );
  }

  if (!surveyState.complete) {
    return (
      <div className="flex justify-around md:mt-10">
        <Survey
          arraySurvey={arraySurvey}
          qIndex={surveyState.qIndex}
          onNav={(dir) =>
            setSurveyState({ ...surveyState, qIndex: surveyState.qIndex + dir })
          }
          onAnswer={handleAnswer}
          onComplete={handleSurveyComplete}
          existingAnswers={surveyState.answers}
          initalScore={initalScore}
        />
      </div>
    );
  }
  return (
    <ResultsDashboard
      initalScore={initalScore}
      answers={surveyState.answers}
      arraySurvey={arraySurvey}
      onEditAnswer={(i) => setEditQuestion({ edit: true, qIndex: i })}
    />
  );
};

export default Dashboard;
