import React from "react";
import "./Content.scss";
import Arrow from "../../assets/arrow.png";
import Ionstien from "../../assets/ionstien.png";

const steps = [
  {
    id: 1,
    stepTitle: "Discover Your Friction Factor 🕵️‍♀️",
    stepDescription:
      "You can't whip up a winning action plan without knowing what roadblocks you and your team might face. Dive into our snappy quiz and unveil your company's Friction Factor! 🔍",
  },
  {
    id: 2,
    stepTitle: "Unlock Your Roadmap to Success 🗺️",
    stepDescription:
      "With a clear understanding of the resistance you'll face while growing your network, you can craft a proactive plan to tackle your friction factor head-on. You got this! 💼",
  },
  {
    id: 3,
    stepTitle: "Learn the Secret Sauce to Remove or Reduce Friction 🌟",
    stepDescription:
      "Once you've conquered the friction factor assessment, get ready to uncover some of the most powerful strategies to banish friction from your business. Let's do this! 💥",
  },
];
const frictionFactors = [
  {
    id: 1,
    pointText:
      "Did you know there's an invisible force trying to slow you down on your way to success? It's called friction, and it sneaks around, making it harder for you to hit your targets and accomplish your dreams. 😱",
  },
  {
    id: 2,
    pointText:
      "Network Friction loves to play games in three sneaky ways: Internal, External, and Systemic. Internal friction is like that little voice in your head that sometimes doubts your awesomeness. (Pssst! Don't listen to it! You rock! 🎸)",
  },
  {
    id: 3,
    pointText:
      "External friction is all those pesky things beyond your control, like how people see your industry or those ever-changing regulations. Ugh! 🙄",
  },
  {
    id: 4,
    pointText:
      "Systemic friction is tied to the business model you're using to chase your goals.    It might be a bit out of your hands, but it does change from one company to another within the same industry. 🤔",
  },
  {
    id: 5,
    pointText:
      "Introducing our fabulous Friction Factor Calculator! 🚀 (Cue the confetti! 🎊)         This nifty tool zeroes in on systemic friction, helping you navigate the bumpy road      to success.",
  },
  {
    id: 6,
    pointText:
      "You know those income goals and projections based on growth expectations? Well, our calculator will help you understand the friction levels you're dealing with in your current company, so you can make more accurate predictions for future growth. 📈",
  },
  {
    id: 7,
    pointText:
      "Get ready to conquer Network Friction and soar towards your goals with our Friction Factor Calculator! 🌟✨",
  },
];
const exploringFactors = [
  {
    id: 1,
    exp: "Ready to take charge and kick friction to the curb? You've already taken the first step by identifying that pesky friction as your number one nemesis. Now, it's time to assess just how much it's holding you back and make a master plan to crush it! 💪",
  },
  {
    id: 2,
    exp: "If external and systemic friction aren't too much of a bother, the easiest way to send friction packing is by focusing on that internal friction component. But remember, friction loves to team up, so if your external or systemic friction scores are sky-high, your internal friction factor might not make much of a difference. 🤷‍♀️",
  },
  {
    id: 3,
    exp: "Once you've got your score and had a blast playing with our calculator to see your growth potential, you'll be ready to pinpoint where you need to focus your business improvement efforts. 🎯",
  },
  {
    id: 4,
    exp: "And guess what? We've got a treasure trove of secrets to help you overcome the impact of systemic friction. Once you're done having fun with our friction calculator, simply follow the link to unlock the knowledge you need to dominate the friction game and emerge victorious! 🏆✨",
  },
];

const Content = () => {
  return (
    <>
      <section className="main-content py-60 py-lg-80 py-xxl-100 py-xxxl-120">
        <div className="container text-center">
          <h3 className="mb-12 mb-lg-60 fw-bold">
            Why you should take this quiz
          </h3>
          <div className="steps-list">
            {steps.map((step) => (
              <div key={step.id} className="mb-8">
                <div className="d-flex align-iyems-center justify-content-center">
                  <div>
                    <img src={Arrow} alt={`Point ${step.id}`} />
                  </div>
                  <div className="ms-2">
                    <h5 className="text-success fw-semibold">
                      {step.stepTitle}
                    </h5>
                  </div>
                </div>
                <p className="fw-normal w-lg-75 mx-auto">
                  {step.stepDescription}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-12 mt-lg-60 mt-xxxl-80 bg-info py-12 py-lg-60">
          <div className="container text-center">
            <h3 className="text-primary mb-9 fw-bold">
              Why are network marketing growth projections always wrong?
            </h3>
            <p className="text-danger mb-0 fs-28">
              The answer: Network Friction
            </p>
          </div>
        </div>
        <div className="container mt-60 mt-lg-80 mt-xxl-100 mt-xxxl-120">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                src={Ionstien}
                alt="Instien Solving A Problem"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 mt-12 mt-lg-0">
              <h5 className="text-success fw-semibold mb-4">
                Calculating your Friction Factor
              </h5>

              {frictionFactors.map((factor) => (
                <p key={factor.id} className="fw-normal">
                  {factor.pointText}
                </p>
              ))}
            </div>
            {/* <div className="col-12 mt-12 mt-60 mt-xxl-80">
              <h5 className="text-success text-center fw-semibold mb-4">
                Hey there, friction-fighter! 🥊
              </h5>
              {exploringFactors.map((factor) => (
                <p key={factor.id} className="fw-normal">
                  {factor.exp}
                </p>
              ))}
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Content;
