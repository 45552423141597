import React from "react";
import { Card, Button, TextField } from "@mui/material";

const displayFields = [
  {
    name: "seedValue",
    text: "How many people will you personally recruit?",
    min: 0,
    step: "1",
  },
  {
    name: "growthValue",
    text: "How many people will members within your network recruit?",
    min: 0,
    step: "0.1",
  },
];

const ParameterCard = ({ onTextChange, scores, onRecalculate }) => {
  const handleTextChange = (field) => (e) => {
    onTextChange(field, e.target.value);
  };

  return (
    <Card className="p-2">
      <div
        className="flex justify-center pb-5 p-3 text-badTeal text-3xl"
        variant="h4"
        component="div"
      >
        Network Growth Parameters
      </div>
      <div className=" flex Class flex-col">
        {displayFields.map((field) => {
          return (
            <div className="flex mb-4 text-xl" key={field.name}>
              <div className="pl-3 flex-1 flex justify-around text-badTeal ">
                {field.text}
              </div>
              <div className="flex-1 flex justify-around">
                <TextField
                  className="w-20"
                  type="number"
                  inputProps={{
                    inputProps: { min: 0, step: field.step },
                    style: { textAlign: "center", paddingTop: "18px" },
                  }}
                  size="small"
                  value={scores[field.name]}
                  onChange={handleTextChange(field.name)}
                ></TextField>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-around mb-10">
        <div className="flex-1 ml-5 text-red-500">
          {scores.sliceOptions < scores.frictionFactor
            ? "Slice Options must be greater than or equal to Friction Factor"
            : null}
        </div>
        <div className="flex-1 flex justify-around">
          <Button
            disabled={scores.sliceOptions < scores.frictionFactor}
            sx={{
              borderRadius: 28,
              backgroundColor: "#417980",
              paddingLeft: "35px",
              paddingRight: "35px",
            }}
            variant="contained"
            onClick={() => onRecalculate()}
          >
            Recalculate
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ParameterCard;
