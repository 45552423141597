import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    width: "50%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    height: "80vh",
    padding: "25px",
    transform: "translate(-50%, -50%)",
  },
};

const PrivacyPolicyModal = ({ type }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal(e) {
    e.preventDefault();
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  React.useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [modalIsOpen]);

  return (
    <>
      <a className="text-white footer-text fw-semibold cursor-pointer" onClick={openModal}>
        {type === "tou" && "Terms of Use"}
        {type === "disclaimer" && "Privacy Policy"}
      </a>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="flex flex-col">
          <div className="flex justify-between">
            <button onClick={closeModal}>❌</button>
          </div>
          {type === "tou" && (
            <iframe
              src={require("../../assets/WebsiteTermsofUse.txt")}
              frameborder="0"
              className="grow"
              style={{ height: "70vh" }}
            ></iframe>
          )}
          {type === "disclaimer" && (
            <iframe
              src={require("../../assets/OnlinePrivacyPolicy.txt")}
              frameborder="0"
              className="grow"
              style={{ height: "70vh" }}
            ></iframe>
          )}
        </div>
      </Modal>
    </>
  );
};

export default PrivacyPolicyModal;
