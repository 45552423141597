import React from "react";
import { Card } from "@mui/material";

const NetworkFrictionParametersCard = ({
  answers,
  arraySurvey,
  onEditAnswer,
}) => {
  return (
    <Card className="p-2">
      <div
        className="flex justify-center pb-5 p-3 text-badTeal text-3xl"
        variant="h4"
        component="div"
      >
        Network Friction Parameters
      </div>
      <div className="grid lg:grid-flow-col  lg:grid-rows-5 text-xl">
        {arraySurvey.slice(0, 10).map((question, i) => {
          const questionSelection = answers[i];
          const rating =
            question.choices[questionSelection].value.frictionFactor;
          let borderColor;
          if (rating < 5) {
            borderColor = "border-green-400";
          } else if (rating < 8) {
            borderColor = "border-yellow-200";
          } else {
            borderColor = "border-rose-600";
          }

          return (
            <div className="m-1 " key={question.name}>
              <button
                className={`border-2 m-1 pb-1  p-3 pt-1 rounded-xl ${borderColor}`}
                onClick={() => onEditAnswer(i)}
              >
                {question.alt}
              </button>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default NetworkFrictionParametersCard;
