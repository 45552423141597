// Header.jsx
import React, { useEffect, useState } from "react";
import FrictionLogo from "../../assets/logo-white.png";
import { NavLink, useLocation } from "react-router-dom";

function Header() {
  const location = useLocation();
  const [surveyExists, setSurveyExists] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const checkUser = () => {
    const user = localStorage.getItem("networkUser");
    if (user) {
      setUserExists(true);
    }
  };

  const checkSurvey = () => {
    const survey = localStorage.getItem("surveyAnswers");
    if (survey) {
      setSurveyExists(true);
    }
  };

  useEffect(() => {
    checkSurvey();
    checkUser();
  }, [location]);

  return (
    <header className="header py-4 bg-warning">
      <nav>
        <div className="container d-flex align-items-center justify-content-between">
          <div>
            <a href="#">
              <img src={FrictionLogo} alt="Friction Network" />
            </a>
          </div>
          <ul className="ps-0 mb-0">
            <li>
              {userExists && (
                <>
                  {location.pathname !== "/survey" ? (
                    <NavLink to="/survey">
                      {surveyExists ? "Dashboard" : "Survey"}
                    </NavLink>
                  ) : (
                    <NavLink to="/">Home</NavLink>
                  )}
                </>
              )}
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
